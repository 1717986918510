<template>
    <div class="h5-container">
        <div class="page-title">{{$t('Module.CurrencyTrading')}}</div>
        <tradingTop />
        <tradingView />
        <tradingOrders />
        <tradingFormWrap />
        <tabbar :activeId="2"></tabbar>
    </div>
</template>

<script>
    export default {
        components: {
            tradingTop: () => import('../trading/top.vue'),
            tradingOrders: () => import('../trading/orders2.vue'),
            tradingView: () => import("@/views/trading/chart"),
            tradingFormWrap: () => import('../trading/FormWrap2.vue'),
            tabbar: () => import('@/components/tabbar.vue')
        },
        data() {
            return {
                
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        padding-bottom: 14.6rem;
        background-color: #f6f6f6;
        
        .page-title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 4.8rem;
            box-sizing: border-box;
            padding: 0 1.2rem;
            margin-bottom: 4px;
            font-size: 1.7rem;
            font-weight: bold;
            color: #000000;
            background-color: #FFFFFF;
        }
    }
</style>